import { render, staticRenderFns } from "./completed.vue?vue&type=template&id=29fc5bc7&scoped=true&"
import script from "./completed.vue?vue&type=script&lang=js&"
export * from "./completed.vue?vue&type=script&lang=js&"
import style0 from "./completed.vue?vue&type=style&index=0&id=29fc5bc7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29fc5bc7",
  null
  
)

export default component.exports